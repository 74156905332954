.main {
    width: 100%;
    cursor: pointer;

    .img {
        width: 100%;
    }

    .title {
        width: 100%;
        text-align: center;
    }
}