.login-container {
  input {
    width: 260px;
  }

  .login-footer {
    margin-top: 30px;
  }
}

.link-login {
  color: rgb(122, 122, 122);
  display: block;
  text-align: center;
  margin-bottom: 30px;
  margin-top: 20px;
  text-decoration: underline;

  &:hover {
    color: black;
  }
}
