@import "../../styles.scss";

.switch {
  top: 2px;
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  user-select: none;

  input {
    opacity: 0;
    width: 0;
    height: 0;

    &:checked + .slider {
      background-color: $icafal-orange;
    }

    &:focus + .slider {
      box-shadow: 0 0 1px $icafal-orange;
    }

    &:checked + .slider:before {
      transform: translateX(26px);
    }
  }
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;

  &:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: 0.4s;
  }

  &.round {
    border-radius: 34px;

    &:before {
      border-radius: 50%;
    }
  }
}
