@import "../../styles.scss";

.item-option {
  border-bottom: solid 1px #ddd;
  display: flex;
  justify-content: space-between;
  padding: 5px;
  margin-bottom: 5px;

  button {
    background-color: white;
    border: none;
    padding: 0;

    &:focus {
      outline: 0;
    }
  }
}
