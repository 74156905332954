@import "../../styles.scss";

.content-title-sortable {
  width: 80%;
}

.drag-handle {
  width: 15%;
}
.link-button, .link-button-item {
    background-color: rgba(0, 0, 0, 0);
    border: none;
    color: $icafal-orange-dark;
    white-space: nowrap;
    width: 85%;
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: left;
    &:focus {
        outline: 0;
    }
}

.link-button-item {
  width: 70%;
  color: inherit;
}