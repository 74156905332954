.register-container {
  margin: 50px 0;

  &__section {
    margin: 20px 0;
  }

  &__field {
    padding: 15px 20px;
    border-bottom: dashed 1px #ddd;
    border-right: dashed 1px #ddd;
  }

  .error-valid-form {
    color: red;
    font-weight: bold;
    text-align: right;
  }

  .signature {
    background-color: white;
    border: solid #999 1px;
    border-radius: 5px;
  }

  .error-loading-form {
    color: red;
    font-size: 1.5em;
    padding: 40px;
    text-align: center;
  }
}

.signature {
  background-color: white;
  border: solid #999 1px;
  border-radius: 5px;
}

.error-valid-form {
  color: red;
  font-weight: bold;
  text-align: right;
}
