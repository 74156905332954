.sendmodal-content {
    background-color: rgba(0, 0, 0, 0.419);
    width: 100%;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 50000;

    .sendmodal-body {
        padding: 30px;
        background-color: #FFF;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 5px;

        span {
            padding: 20px;
        }
    }
}